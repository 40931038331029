import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../components/HelloWorld.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../page/home.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../page/about.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "about" */ '../page/news.vue')
    },

]

const router = new VueRouter({
    mode: '',
    base: process.env.BASE_URL,
    routes
})

export default router